/* eslint-disable */

<template>
  <div>
    <movable-popup
      refVal="movableEmailRef"
      title="Exchange Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="emailLogsPopup"
      v-on:close="emailLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="emailLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Timestamp</vs-th>
            <vs-th>From</vs-th>
            <vs-th>To</vs-th>
            <vs-th>Src IP</vs-th>
            <vs-th>Src Port</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['From']}`">
                {{ `${attach['From']}` }}
              </vs-td>

              <vs-td :data="`${attach['To']}`">
                {{ `${attach['To']}` }}
              </vs-td>

              <vs-td :data="`${attach['SIP']}`">
                {{ `${attach['SIP']}` }}
              </vs-td>

              <vs-td :data="`${attach['SP']}`">
                {{ `${attach['SP']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>
    <movable-popup
      refVal="movableFileRef"
      title="NFS Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="fileLogsPopup"
      v-on:close="fileLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="fileLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Timestamp</vs-th>
            <vs-th>URL</vs-th>
            <vs-th>Src IP</vs-th>
            <vs-th>Src Port</vs-th>
            <vs-th>Dest IP</vs-th>
            <vs-th>Dest Port</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['URL']}`">
                {{ `${attach['URL']}` }}
              </vs-td>

              <vs-td :data="`${attach['SIP']}`">
                {{ `${attach['SIP']}` }}
              </vs-td>

              <vs-td :data="`${attach['SP']}`">
                {{ `${attach['SP']}` }}
              </vs-td>

              <vs-td :data="`${attach['DIP']}`">
                {{ `${attach['Dest IP']}` }}
              </vs-td>

              <vs-td :data="`${attach['PORT']}`">
                {{ `${attach['Dest Port']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>
    <movable-popup
      refVal="movableLogRef"
      title="Splunk Logs - Click Here to Drag This Popup"
      width="51vw"
      :active="SIEMLogsPopup"
      v-on:close="SIEMLogsPopup = false"
      padding="0"
    >
      <template v-slot:content>
        <vs-table :data="SIEMLogs" max-height="500px">
          <template slot="thead">
            <vs-th>Timestamp</vs-th>
            <vs-th>Event ID</vs-th>
            <vs-th>Log Message</vs-th>
            <vs-th>Username</vs-th>
            <vs-th>Process Name</vs-th>
          </template>
          <template slot-scope="{ data }">
            <vs-tr v-for="attach in data" :key="attach['Username']">
              <vs-td :data="`${attach['DT']}`">
                {{ `${attach['DT']}` }}
              </vs-td>

              <vs-td :data="`${attach['EID']}`">
                {{ `${attach['EID']}` }}
              </vs-td>

              <vs-td :data="`${attach['LM']}`">
                {{ `${attach['LM']}` }}
              </vs-td>

              <vs-td :data="`${attach['AN']}`">
                {{ `${attach['AN']}` }}
              </vs-td>

              <vs-td :data="`${attach['PN']}`">
                {{ `${attach['PN']}` }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </template>
    </movable-popup>

    <div class="vx-row">
      <div class="vx-col w-1/4">
        <div class="main-border rounded-lg vx-card overflow-hidden">
          <div class="flex items-center justify-between mb-2 p-4 bg-light main-bottom-border">
            <div class="flex">
              <feather-icon icon="ShieldIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Log Center</h3>
            </div>
          </div>
          <div class="p-5 pt-3 pb-2">
            <div
              @click="togglePopup('emailLogsPopup')"
              class="flex flex-row gap-x-3 justify-center items-center p-6 rounded-lg main-border bg-light mb-4 cursor-pointer"
            >
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/368769034444865536/exchange-logs.png"
                width="35"
              />
              <h3 class="font-bold">Exchange Logs</h3>
            </div>
            <div
              @click="togglePopup('fileLogsPopup')"
              class="flex flex-row gap-x-3 justify-center items-center p-3 rounded-lg main-border bg-light mb-4 cursor-pointer"
            >
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/368769034444865536/nfs-logs-icon.png"
                width="50"
              />
              <h3 class="font-bold mr-4">NFS Logs</h3>
            </div>
            <div
              @click="togglePopup('SIEMLogsPopup')"
              class="flex flex-row gap-x-3 justify-center items-center p-5 rounded-lg main-border bg-light mb-4 cursor-pointer"
            >
              <img
                src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/368769034444865536/splunk-logo.png"
                width="40"
              />
              <h3 class="font-bold mr-1">Splunk Events</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col w-3/4">
        <div class="main-border overflow-hidden rounded-lg vx-card mb-6">
          <div
            class="flex items-center justify-between p-4 bg-light main-bottom-border"
            :class="[this.reportAnalysisComplete === true ? 'bg-success-gradient' : '']"
          >
            <div class="flex">
              <feather-icon icon="SearchIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Post Infection Identification</h3>
            </div>
            <div class="flex">
              <feather-icon v-if="true" icon="CheckCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
              <feather-icon v-if="false" icon="XCircleIcon" svgClasses="w-8 h-8 ml-4 self-center" />
            </div>
          </div>
          <div class="p-4">
            <div class="grid grid-cols-3 gap-4">
              <div
                class="flex flex-col justify-between p-5 bg-light rounded-lg"
                :key="item.name"
                v-for="item in postInfectionIdentification"
              >
                <!-- <img width="100" :src="item.imgsrc"> -->
                <div class="flex flex-row items-center gap-x-3">
                  <font-awesome-icon :icon="item.icon" class="h-8 w-8" :class="item.color" />

                  <h3>{{ item.name }}</h3>
                </div>

                <div class="flex mt-3" v-if="item.type === 'number'">
                  <feather-icon @click="item.value--" icon="MinusCircleIcon" svgClasses="h-7 w-7 mr-3 hover:text-primary cursor-pointer" />
                  <vs-input class="font-bold text-center w-1/3" v-model="item.value" type="number" />
                  <feather-icon @click="item.value++" icon="PlusCircleIcon" svgClasses="h-7 w-7 ml-3 hover:text-primary cursor-pointer" />
                </div>
                <div class="flex" v-if="item.type === 'time'">
                  <flat-pickr :config="configDateTimePicker" v-if="item.type === 'time'" v-model="item.value" placeholder="Choose Time" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="main-border overflow-hidden vx-card">
          <div class="flex items-center justify-between p-5 bg-light main-bottom-border">
            <div class="flex">
              <feather-icon icon="FileTextIcon" svgClasses="w-8 h-8 mr-1 self-center" />
              <h3 class="font-bold self-center ml-1">Teams Post</h3>
            </div>
            <h3 class="font-light self-center">#CorpUpdates-Tech</h3>
          </div>
          <img
            src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/368769034444865536/team-post.png"
            draggable="false"
            class="w-full rounded-lg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      configDateTimePicker: {
        enableTime: true,
        enableSeconds: false,
        noCalendar: true,
      },

      postInfectionIdentification: [
        {
          name: 'Devices Infected',
          icon: 'fa-solid fa-bug',
          color: 'text-danger',
          value: null,
          answer: 2,
          type: 'number',
          marked: true,
        },
        {
          name: 'Message Posted',
          icon: 'fa-solid fa-comments',
          color: 'text-warning',
          value: 0,
          answer: '11:26',
          type: 'time',
          marked: true,
        },
        {
          name: 'Link Clicks',
          icon: 'fa-solid fa-link',
          color: 'text-primary',
          value: null,
          answer: 4,
          type: 'number',
          marked: true,
        },
      ],
      emailLogsPopup: false,
      emailLogs: [
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'felipe.lane@fujjo.com',
          SIP: '10.60.0.110',
          SP: 56879,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'angeline.hood@fujjo.com',
          SIP: '10.60.0.117',
          SP: 43525,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'monica.cannon@fujjo.com',
          SIP: '10.60.0.139',
          SP: 54877,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'karen.jacobson@fujjo.com',
          SIP: '10.60.0.185',
          SP: 44627,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'kaylen.stark@fujjo.com',
          SIP: '10.60.0.47',
          SP: 54727,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'amiah.rogers@fujjo.com',
          SIP: '10.60.0.155',
          SP: 60582,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'kiersten.wiley@fujjo.com',
          SIP: '10.60.0.34',
          SP: 42246,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'erika.donaldson@fujjo.com',
          SIP: '10.60.0.181',
          SP: 33066,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'vance.joyce@fujjo.com',
          SIP: '10.60.0.155',
          SP: 65254,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'ashlyn.maxwell@fujjo.com',
          SIP: '10.60.0.185',
          SP: 54712,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'olive.booker@fujjo.com',
          SIP: '10.60.0.34',
          SP: 44384,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'sasha.black@fujjo.com',
          SIP: '10.60.0.61',
          SP: 51662,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'zechariah.cortez@fujjo.com',
          SIP: '10.60.0.197',
          SP: 47982,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'fernanda.reyes@fujjo.com',
          SIP: '10.60.0.47',
          SP: 48618,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'miah.gordon@fujjo.com',
          SIP: '10.60.0.34',
          SP: 57654,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'bentley.patterson@fujjo.com',
          SIP: '10.60.0.139',
          SP: 39933,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'adam.bradshaw@fujjo.com',
          SIP: '10.60.0.181',
          SP: 63378,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'krish.bowen@fujjo.com',
          SIP: '10.60.0.61',
          SP: 51015,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'jair.walls@fujjo.com',
          SIP: '10.60.0.41',
          SP: 43928,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'blaine.hoffman@fujjo.com',
          SIP: '10.60.0.25',
          SP: 76541,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'gaige.steele@fujjo.com',
          SIP: '10.60.0.16',
          SP: 20875,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'alexis.shelton@fujjo.com',
          SIP: '10.60.0.28',
          SP: 61234,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'lydia.riggs@fujjo.com',
          SIP: '10.60.0.31',
          SP: 97485,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'catherine.jefferson@fujjo.com',
          SIP: '10.60.0.98',
          SP: 32156,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'jude.cooley@fujjo.com',
          SIP: '10.60.0.139',
          SP: 58674,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'johnny.spears@fujjo.com',
          SIP: '10.60.0.119',
          SP: 93621,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'billy.garrett@fujjo.com',
          SIP: '10.60.0.104',
          SP: 85724,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'ella.macdonald@fujjo.com',
          SIP: '10.60.0.174',
          SP: 19463,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'octavio.kent@fujjo.com',
          SIP: '10.60.0.72',
          SP: 37291,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'layla.higgins@fujjo.com',
          SIP: '10.60.0.155',
          SP: 50483,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'felipe.lane@fujjo.com',
          SIP: '10.60.0.58',
          SP: 69735,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'angeline.hood@fujjo.com',
          SIP: '10.60.0.86',
          SP: 82547,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'monica.cannon@fujjo.com',
          SIP: '10.60.0.81',
          SP: 36785,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'erika.donaldson@fujjo.com',
          SIP: '10.60.0.123',
          SP: 54019,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          From: 'fujjoall@fujjo.onmicrosoft.com',
          To: 'vance.joyce@fujjo.com',
          SIP: '10.60.0.139',
          SP: 28476,
        },
      ],
      fileLogsPopup: false,
      fileLogs: [
        {
          DT: '04/13/2022 11:26:41:61 AM',
          URL: 'ovahia-leggings.com',
          SIP: '10.60.0.47',
          SP: 34317,
          'Dest IP': '139.118.246.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:40:14 AM',
          URL: 'cadence.com',
          SIP: '10.60.0.34',
          SP: 52770,
          'Dest IP': '216.95.194.64',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:39:51 AM',
          URL: 'veritas.com',
          SIP: '10.60.0.181',
          SP: 54938,
          'Dest IP': '143.162.53.51',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:38:08 AM',
          URL: 'ovahia-leggings.com',
          SIP: '10.60.0.34',
          SP: 48768,
          'Dest IP': '139.118.246.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:37:11 AM',
          URL: 'advantech.com',
          SIP: '10.60.0.117',
          SP: 44816,
          'Dest IP': '42.117.109.0',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:36:05 AM',
          URL: 'ovahia-leggings.com',
          SIP: '10.60.0.139',
          SP: 51090,
          'Dest IP': '139.118.246.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:35:19 AM',
          URL: 'microfocus.net',
          SIP: '10.60.0.61',
          SP: 55863,
          'Dest IP': '66.107.159.232',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:34:15 AM',
          URL: 'nvidia-dgx.com',
          SIP: '10.60.0.34',
          SP: 55838,
          'Dest IP': '125.59.172.106',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:33:19 AM',
          URL: 'ovahia-leggings.com',
          SIP: '10.60.0.181',
          SP: 58303,
          'Dest IP': '139.118.246.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:17:47 AM',
          URL: 'seagate.com',
          SIP: '10.60.0.155',
          SP: 58188,
          'Dest IP': '198.73.100.157',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          URL: 'vmware.com',
          SIP: '10.60.0.139',
          SP: 36043,
          'Dest IP': '208.98.67.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:25:30:10 AM',
          URL: 'fujjo.com',
          SIP: '10.60.0.110',
          SP: 44000,
          'Dest IP': '172.1.51.117',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:24:29:31 AM',
          URL: 'cadence.com',
          SIP: '10.60.0.155',
          SP: 49203,
          'Dest IP': '216.95.194.64',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:23:28:38 AM',
          URL: 'seagate.com',
          SIP: '10.60.0.61',
          SP: 39307,
          'Dest IP': '198.73.100.157',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:22:28:44 AM',
          URL: 'microfocus.net',
          SIP: '10.60.0.47',
          SP: 44695,
          'Dest IP': '66.107.159.232',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:21:27:19 AM',
          URL: 'juniper.net',
          SIP: '10.60.0.185',
          SP: 61395,
          'Dest IP': '208.4.87.157',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:20:26:10 AM',
          URL: 'veritas.com',
          SIP: '10.60.0.185',
          SP: 45458,
          'Dest IP': '143.162.53.51',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:19:26:71 AM',
          URL: 'nvidia-dgx.com',
          SIP: '10.60.0.139',
          SP: 35407,
          'Dest IP': '125.59.172.106',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:17:25:68 AM',
          URL: 'vmware.com',
          SIP: '10.60.0.139',
          SP: 34959,
          'Dest IP': '208.98.67.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:13:24:94 AM',
          URL: 'fujjo.com',
          SIP: '10.60.0.181',
          SP: 43235,
          'Dest IP': '172.1.51.117',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:10:22:13 AM',
          URL: 'aja.com',
          SIP: '10.60.0.61',
          SP: 56263,
          'Dest IP': '253.157.117.1',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:06:21:13 AM',
          URL: 'sonicwall.com',
          SIP: '10.60.0.47',
          SP: 50628,
          'Dest IP': '73.49.75.52',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:03:19:13 AM',
          URL: 'hcltechsw.com',
          SIP: '10.60.0.117',
          SP: 39006,
          'Dest IP': '156.31.230.47',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:03:17:17 AM',
          URL: 'logmeinhamachi.io',
          SIP: '10.60.0.197',
          SP: 31789,
          'Dest IP': '111.78.206.125',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:02:17:81 AM',
          URL: 'seagate.com',
          SIP: '10.60.0.34',
          SP: 61091,
          'Dest IP': '198.73.100.157',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 11:01:15:96 AM',
          URL: 'cadence.com',
          SIP: '10.60.0.185',
          SP: 57584,
          'Dest IP': '216.95.194.64',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:59:11:94 AM',
          URL: 'advantech.com',
          SIP: '10.60.0.34',
          SP: 33517,
          'Dest IP': '42.117.109.0',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:57:58:87 AM',
          URL: 'juniper.net',
          SIP: '10.60.0.110',
          SP: 51800,
          'Dest IP': '208.4.87.157',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:56:04:91 AM',
          URL: 'fujjo.com',
          SIP: '10.60.0.181',
          SP: 34395,
          'Dest IP': '172.1.51.117',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:54:28:65 AM',
          URL: 'nvidia-dgx.com',
          SIP: '10.60.0.139',
          SP: 51597,
          'Dest IP': '125.59.172.106',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:52:59:17 AM',
          URL: 'vmware.com',
          SIP: '10.60.0.47',
          SP: 49803,
          'Dest IP': '208.98.67.71',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:52:16:90 AM',
          URL: 'microfocus.net',
          SIP: '10.60.0.34',
          SP: 42967,
          'Dest IP': '66.107.159.232',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:51:41:64 AM',
          URL: 'veritas.com',
          SIP: '10.60.0.185',
          SP: 50377,
          'Dest IP': '143.162.53.51',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:51:31:66 AM',
          URL: 'fujjo.com',
          SIP: '10.60.0.155',
          SP: 57716,
          'Dest IP': '172.1.51.117',
          'Dest Port': 80,
        },
        {
          DT: '04/13/2022 10:50:18:04 AM',
          URL: 'microsoft-signin.com',
          SIP: '10.60.0.197',
          SP: 32806,
          'Dest IP': '101.122.187.123',
          'Dest Port': 80,
        },
      ],
      SIEMLogsPopup: false,
      SIEMLogs: [
        {
          DT: '04/13/2022 11:26:41:61 AM',
          EID: 15911,
          LM: 'A new process has been created',
          AN: 'angeline.hood',
          PN: 'excel.exe',
        },
        {
          DT: '04/13/2022 11:26:40:14 AM',
          EID: 15800,
          LM: 'A new process has been created',
          AN: 'miah.gordon',
          PN: 'winword.exe',
        },
        {
          DT: '04/13/2022 11:26:39:51 AM',
          EID: 15228,
          LM: 'A new process has been created',
          AN: 'olive.booker',
          PN: 'Azure_VPN_Setup_x86_64.exe',
        },
        {
          DT: '04/13/2022 11:26:38:08 AM',
          EID: 15071,
          LM: 'A new process has been created',
          AN: 'lydia.riggs',
          PN: 'winlog.exe',
        },
        {
          DT: '04/13/2022 11:26:37:11 AM',
          EID: 15333,
          LM: 'A new process has been created',
          AN: 'layla.higgins',
          PN: 'svchost.exe',
        },
        {
          DT: '04/13/2022 11:26:36:05 AM',
          EID: 15786,
          LM: 'A new process has been created',
          AN: 'lydia.riggs',
          PN: 'notepad.exe',
        },
        {
          DT: '04/13/2022 11:26:35:19 AM',
          EID: 15505,
          LM: 'A new process has been created',
          AN: 'bentley.patterson',
          PN: 'Azure_VPN_Setup_x86_64.exe',
        },
        {
          DT: '04/13/2022 11:26:34:15 AM',
          EID: 15551,
          LM: 'An accounts was logged off',
          AN: 'octavio.kent',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:26:33:19 AM',
          EID: 15256,
          LM: 'An accounts was successfully logged on',
          AN: 'jude.cooley',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:26:17:47 AM',
          EID: 15524,
          LM: 'A new process has been created',
          AN: 'bentley.patterson',
          PN: 'Azure_VPN_Setup_x86_64.exe',
        },
        {
          DT: '04/13/2022 11:26:01:98 AM',
          EID: 15455,
          LM: 'A new process has been created',
          AN: 'felipe.lane',
          PN: 'outlook.exe',
        },
        {
          DT: '04/13/2022 11:25:30:10 AM',
          EID: 15782,
          LM: 'A process has exited.',
          AN: 'zechariah.cortez',
          PN: 'outlook.exe',
        },
        {
          DT: '04/13/2022 11:24:29:31 AM',
          EID: 15997,
          LM: 'A new process has been created',
          AN: 'alexis.shelton',
          PN: 'winword.exe',
        },
        {
          DT: '04/13/2022 11:23:28:38 AM',
          EID: 15423,
          LM: 'An accounts was logged off',
          AN: 'kaylen.stark',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:22:28:44 AM',
          EID: 15760,
          LM: 'An accounts was successfully logged on',
          AN: 'zechariah.cortez',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:21:27:19 AM',
          EID: 15371,
          LM: 'An accounts was logged off',
          AN: 'krish.bowen',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:20:26:10 AM',
          EID: 15061,
          LM: 'A new process has been created',
          AN: 'jude.cooley',
          PN: 'outlook.exe',
        },
        {
          DT: '04/13/2022 11:19:26:71 AM',
          EID: 15045,
          LM: 'An accounts was successfully logged on',
          AN: 'blaine.hoffman',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:17:25:68 AM',
          EID: 15862,
          LM: 'An accounts was logged off',
          AN: 'gaige.steele',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:13:24:94 AM',
          EID: 15541,
          LM: 'A new process has been created',
          AN: 'felipe.lane',
          PN: 'notepad.exe',
        },
        {
          DT: '04/13/2022 11:10:22:13 AM',
          EID: 15169,
          LM: 'An accounts was successfully logged on',
          AN: 'fernanda.reyes',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 11:06:21:13 AM',
          EID: 15577,
          LM: 'A new process has been created',
          AN: 'fernanda.reyes',
          PN: 'explorer.exe',
        },
        {
          DT: '04/13/2022 11:03:19:13 AM',
          EID: 15262,
          LM: 'A new process has been created',
          AN: 'miah.gordon',
          PN: 'iexplore.exe',
        },
        {
          DT: '04/13/2022 11:03:17:17 AM',
          EID: 15066,
          LM: 'A new process has been created',
          AN: 'layla.higgins',
          PN: 'iexplore.exe',
        },
        {
          DT: '04/13/2022 11:02:17:81 AM',
          EID: 15918,
          LM: 'A new process has been created',
          AN: 'gaige.steele',
          PN: 'chrome.exe',
        },
        {
          DT: '04/13/2022 11:01:15:96 AM',
          EID: 15837,
          LM: 'A new process has been created',
          AN: 'angeline.hood',
          PN: 'svchost.exe',
        },
        {
          DT: '04/13/2022 10:59:11:94 AM',
          EID: 15852,
          LM: 'A new process has been created',
          AN: 'kiersten.wiley',
          PN: 'putty.exe',
        },
        {
          DT: '04/13/2022 10:57:58:87 AM',
          EID: 15459,
          LM: 'An accounts was successfully logged on',
          AN: 'krish.bowen',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 10:56:04:91 AM',
          EID: 15858,
          LM: 'A new process has been created',
          AN: 'adam.bradshaw',
          PN: 'iexplore.exe',
        },
        {
          DT: '04/13/2022 10:54:28:65 AM',
          EID: 15096,
          LM: 'An accounts was successfully logged on',
          AN: 'olive.booker',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 10:52:59:17 AM',
          EID: 15936,
          LM: 'A new process has been created',
          AN: 'kiersten.wiley',
          PN: 'explorer.exe',
        },
        {
          DT: '04/13/2022 10:52:16:90 AM',
          EID: 15793,
          LM: 'A process has exited.',
          AN: 'adam.bradshaw',
          PN: 'svchost.exe',
        },
        {
          DT: '04/13/2022 10:51:41:64 AM',
          EID: 15921,
          LM: 'An accounts was successfully logged on',
          AN: 'kaylen.stark',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 10:51:31:66 AM',
          EID: 15648,
          LM: 'An accounts was successfully logged on',
          AN: 'octavio.kent',
          PN: 'Isass.exe',
        },
        {
          DT: '04/13/2022 10:50:18:04 AM',
          EID: 15411,
          LM: 'An accounts was successfully logged on',
          AN: 'catherine.jefferson',
          PN: 'Isass.exe',
        },
      ],
    };
  },
  methods: {
    togglePopup(popup) {
      if (this[popup] === true) {
        this[popup] = 'transition_out';
        return setTimeout(() => {
          this[popup] = false;
        }, 400);
      }
      this[popup] = true;
    },
    markSimulation() {
      let success = true;
      let totalScore = 0;
      if (parseInt(this.postInfectionIdentification[2].value) === this.postInfectionIdentification[2].answer) {
        totalScore++;
        this.postInfectionIdentification[2].marked = true;
      } else {
        success = false;
        this.postInfectionIdentification[2].marked = false;
      }

      if (parseInt(this.postInfectionIdentification[0].value) === this.postInfectionIdentification[0].answer) {
        totalScore++;
        this.postInfectionIdentification[0].marked = true;
      } else {
        success = false;
        this.postInfectionIdentification[0].marked = false;
      }

      if (this.postInfectionIdentification[1].value === this.postInfectionIdentification[1].answer) {
        totalScore++;
        this.postInfectionIdentification[1].marked = true;
      } else {
        success = false;
        this.postInfectionIdentification[1].marked = false;
      }
      this.reportAnalysisComplete = success;

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    flatPickr,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.line-across {
  position: absolute;
  width: 65.875%;
  height: 4px;
  background-color: #0285e4;
}

.line-up {
  position: absolute;
  width: 4px;
  height: 200px;
  background-color: #0285e4;
}

.server-container {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  min-width: 165px;
  padding: 10px;
  border-style: none;
  border-width: 1px;
  border-color: #fff;
  border-radius: 0.5rem;
  -o-object-fit: fill;
  object-fit: fill;
}

.server-block {
  background: #1dd1a1;
}

.vs-switch {
  background: #000000ab;
}
</style>
